import { Suspense, useEffect, useState } from 'react';
import { CustomFile } from '../../../global/interfaces';
import Icon from '../../Icon/Icon';
import ThreeDViewer from '../../three-d-viewer/three-d-viewer';
import './detailed-resource-view.scss';

const BrandedAssetImg = ({ url }: { url: string }) => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (url) {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        setDimensions({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
    }
  }, [url]);

  return (
    <div className="detailed-resource-img">
      <img src={url} alt="detailed resource view" />
      <div>
        <p>{dimensions.width}</p>
        <p>x</p>
        <p>{dimensions.height}px</p>
      </div>
    </div>
  );
};

const DetailedResourceView = ({
  name,
  model,
  image,
  texture,
  closeModal,
}: {
  name: string;
  image: string;
  model?: CustomFile;
  texture?: CustomFile;
  closeModal: () => void;
}) => {
  // const { width, height } = await getImageDimensions(image);
  return (
    <Suspense>
      <div className="detailed-resource-view">
        <div className="detailed-resource-view__heading">
          <h2>{name}</h2>
          <button type="button" onClick={closeModal}>
            <Icon name="close" size={24} />
          </button>
        </div>
        <div className="detailed-resource-view__main">
          <h3>Preview size 100%</h3>
          <div className="detailed-resource-view__main_modal">
            {model && texture && <ThreeDViewer objUrl={model.url} textureUrl={texture.url} />}
            {!model && <BrandedAssetImg url={image} />}
          </div>
          {model && <Icon name="rotate" />}
        </div>
      </div>
    </Suspense>
  );
};

export default DetailedResourceView;
