import { Event } from '../../global/interfaces';
import Icon, { IconName } from '../Icon/Icon';
import './input.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | JSX.Element;
  labelPosition?: 'top' | 'left';
  name?: string;
  value: string | number | readonly string[] | undefined;
  className?: string;
  handleInput: (e: Event['Input']) => void;
  placeholder?: string;
  type?: string;
  hideErrorMessage?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  readOnly?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  iconName?: IconName;
  props?: React.InputHTMLAttributes<HTMLInputElement>;
}

const Input: React.FC<Props> = ({
  value,
  name = '',
  label = null,
  labelPosition = 'top',
  type = 'text',
  className = '',
  placeholder,
  hideErrorMessage = false,
  errorMessage,
  handleInput,
  disabled,
  readOnly,
  inputRef,
  iconName,
  children,
  ...props
}) => {
  return (
    <div className={`input_container ${className} ${labelPosition === 'left' ? 'label_left' : ''}`}>
      {label ? <label>{label}</label> : null}
      <input
        ref={inputRef}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={handleInput}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        {...props}
      />
      {!hideErrorMessage && <p>{errorMessage}</p>}
      {iconName && <Icon name={iconName} />}
      {children}
    </div>
  );
};

export default Input;
